import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, H2, Loading, Row, Text, Wrapper, Box, LabeledTextarea } from '@commonsku/styles';
import { fetchSalesOrderById, fetchOrderDropdowns, reorderOrder, clearToasts, setDisplayEntity, fetchClientById } from '../../store/configureClientPortalStore';
import { themeOptions } from './utils';
import { getImageSrc } from '../../utils';
import Img from '../Img';
import { getOrderById } from '../../selectors';
import { NumberInput } from '../helpers';

import 'react-toastify/dist/ReactToastify.css';
import RawHTML from '../helpers/RawHTML';

function ReorderOrder({ order_id, background = themeOptions.colors.primary0, client_id, contact_email='', onCancel=null }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const client = useSelector(s => _.get(s.client_portal.entities.clients, client_id, null));
    const loading = useSelector(s => s.client_portal.display.loading);
    const reorder_status = useSelector(s => s.client_portal.display.entities.reorder);
    const order = useSelector(s => getOrderById(s.client_portal, {order_id: order_id}));
    const toasts = useSelector(s => s.client_portal.display.toasts || []);

    const [state, setState] = useState({
        items: (order && order.items ? order.items : []).map((v, i) => ({...v, index: i})),
        notes: '',
    });

    useEffect(() => {
        if (toasts && toasts.length > 0) {
            toasts.forEach((t, i) => {
                switch (t.type) {
                    case 'success':
                        toast.success(t.message);
                        break;
                    case 'info':
                        toast.info(t.message);
                        break;
                    case 'warn':
                        toast.warn(t.message);
                        break;
                    case 'error':
                        toast.error(t.message);
                        break;
                    default:
                        break;
                }
            });
            dispatch(clearToasts());
        }
    }, [toasts, dispatch]);

    useEffect(() => {
        if (client_id) {
            Promise.resolve(
                dispatch(fetchClientById(client_id, {email: contact_email || '' }))
            ).then(res => {
                if (res?.error && res?.redirectTo) {
                    navigate(res.redirectTo);
                }
            });
        }
    }, [client_id, contact_email, dispatch, navigate]);

    useEffect(() => {
        if (order_id && client_id) {
            Promise.resolve(
                dispatch(fetchSalesOrderById(order_id, { client_id, email: contact_email || '' }))
            ).then(res => {
                if (res?.error && res?.redirectTo) {
                    navigate(res.redirectTo);
                }
            });
            dispatch(fetchOrderDropdowns(order_id, { client_id, email: contact_email || '' }));
            dispatch(setDisplayEntity({key: 'reorder', value: ''}));
        }
    }, [order_id, client_id, contact_email, dispatch, navigate]);

    useEffect(() => {
        if (order) {
            setState(s => ({...s, items: (order && order.items ? order.items : []).map((v, i) => ({...v, index: i}))}));
        }
    }, [order]);

    const onChangeItemByIndex = React.useCallback((idx, it={}) => {
        setState(s => {
            if (idx < 0 || idx === undefined || idx === null) {
                return s;
            }

            return {
                ...s,
                items: [
                    ...s.items.slice(0, idx),
                    { ...s.items[idx], ...it, },
                    ...s.items.slice(idx+1),
                ],
            };
        });
    }, []);

    function onSubmitReOrder() {
        dispatch(reorderOrder(order_id, state.items, state.notes, {
            client_id,
            email: contact_email || '',
            prev_form_number: order.form_number,
            prev_job_number: order.job_number,
        }));
    }

    function renderOrderItems() {
        return <>
            <Col xs>
                {state.items ? state.items.map((it, i) => (
                     !['SEPARATOR', 'ARTWORK_PO', 'SERVICE_PO', 'SERVICE'].includes(it.parent_type) ?
                        <Item key={`item_${order_id}-${i}`}
                            index={i}
                            item={it}
                            onChange={onChangeItemByIndex}
                        /> : null
                )) : null}
            </Col>
        </>;
    }

    function renderReOrderForm() {
        return (
            order ? <>
                {renderOrderItems()}
                <Col xs padded>
                    <LabeledTextarea
                        label="Notes"
                        placeholder="Order Notes"
                        value={state.notes}
                        onChange={e => {
                            const notes = e.target.value;
                            setState(s => ({ ...s, notes }))
			}}
                        style={{ maxWidth: '100%', minWidth: '100%', minHeight: 50, height: 100 }}
                    />
                </Col>
                <Col xs padded style={{textAlign: 'right'}}>
                    <Button onClick={() => { onCancel && onCancel(); }} style={{
                        background: themeOptions.colors.secondary,
                        borderColor: themeOptions.colors.secondary,
                        color: '#fff',
                        borderRadius: '5px',
                        marginRight: '5px',
                    }}>Cancel</Button>
                    <Button onClick={onSubmitReOrder} style={{
                        background: themeOptions.colors.primary,
                        borderColor: themeOptions.colors.primary,
                        color: '#fff',
                        borderRadius: '5px',
                    }}>Submit Order</Button>
                </Col>
            </> : null
        );
    }

    function renderLoadingOrErrorMsg() {
        return (
            <>
                {loading.orders || loading.reorder ? <Col xs><Loading /></Col> : null}
                <Col xs style={{textAlign: 'center', paddingBottom: 20,}}>
                    <Text style={{
                        fontSize: reorder_status == 'error' ? '20px' : '17px',
                        color: reorder_status == 'error' ? themeOptions.colors.cta : 'initial',
                    }}>{loading.reorder || reorder_status == 'starting'
                        ? 'Re-Ordering...'
                        : (reorder_status == 'error' ? 'Unable to Re-Order' : null)}</Text>
                </Col>
                {reorder_status == 'error' ? <Col xs style={{textAlign: 'center'}}>
                    <Button onClick={() => {
                        dispatch(setDisplayEntity({key: 'reorder', value: ''}));
                    }}>Go to Order Details</Button>
                </Col> : null}
            </>
        );
    }

    function renderReorderFormOrDoneMsg() {
        if (reorder_status != 'done') {
            return renderReOrderForm();
        }
        return (
            <>
                <Col padded xs style={{textAlign: 'center'}}>
                    {_.get(client, 'portal.reorder_message', false)
                        ? <RawHTML>{_.get(client, 'portal.reorder_message', false)}</RawHTML>
                        : <>
                            <Text as="p">Successfully Re-ordered...</Text>
                            <Text as="p">Someone will get back to you soon</Text>
                        </>}
                    <Button onClick={() => {
                        dispatch(setDisplayEntity({key: 'reorder', value: ''}));
                        onCancel();
                    }}>Go back to Order</Button>
                </Col>
            </>
        );
    }

    return (
        <Wrapper bg={background} style={{padding: 20}}>
            <Row>
                <Col xs sm={10} smOffset={1} md={8} mdOffset={2} xl={6} xlOffset={3}>
                    <Box padded>
                        <Row padded>
                            <Col xs padded>
                                <H2 text style={{paddingTop: 20,}}>Reorder</H2>
                            </Col>
                        </Row>
                        <Row padded>
                            {(loading.orders || loading.reorder || (['starting', 'error'].includes(reorder_status)))
                                ? renderLoadingOrErrorMsg()
                                : renderReorderFormOrDoneMsg()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </Wrapper>
    );
}

const default_item_dropdown = [{TBD: 'TBD'}];

const item_row_title_by_parent_type = {
    'ARTWORK_PO': 'Service PO',
    'SERVICE_PO': 'Service PO',
    'SERVICE': 'Services',
};

const item_title_by_parent_type = {
    'PRODUCT': 'Item',
    'ARTWORK_PO': 'SERVICE',
    'SERVICE_PO': 'SERVICE',
    'SERVICE': 'SERVICE',
};

function Item({ index, item, onChange }) {
    const item_colors = useSelector(s => item
        ? s.client_portal.entities.colors
        : default_item_dropdown);
    const item_sizes = useSelector(s => item
        ? s.client_portal.entities.sizes
        : default_item_dropdown);

    const [state, setState] = useState({
        ...item,
        breakdowns: (item && item.breakdowns ? item.breakdowns : []).map((v, i) => parseBreakdown(index, i, v)),
    });

    const onChangeBreakdown = React.useCallback((idx, data={}) => {
        setState(s => {
            if (idx < 0 || idx === undefined || idx === null) {
                return s;
            }
            return {
                ...s,
                breakdowns: [
                    ...s.breakdowns.slice(0, idx),
                    { ...s.breakdowns[idx], ...data, idx, },
                    ...s.breakdowns.slice(idx+1),
                ],
            };
        });
    }, []);

    useEffect(() => {
        setState({
            ...item,
            breakdowns: (item && item.breakdowns ? item.breakdowns : []).map((v, i) => parseBreakdown(index, i, v)),
        });
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(index, state);
        }
    }, [onChange, state, index]);

    return (
        <Row padded style={{
            borderTop: `${index === 0 ? 2 : 0}px solid ${themeOptions.colors.bggray}`,
            borderBottom: `2px solid ${themeOptions.colors.bggray}`,
        }}>
            {item.parent_type == 'PRODUCT' ? <Col xs sm={2} style={{paddingRight: '10px'}}>
                {item.files && item.files.length > 0 ?
                    <Img style={{maxHeight: 80}} src={getImageSrc(item.files[0])} />
                : null}
            </Col> : null}
            <Col xs sm={item.parent_type == 'PRODUCT' ? 10 : 12}>
                <Row>
                    <Col xs>
                        <Text as="p" style={{fontWeight: 'bold', fontSize: '18px'}}>{item_row_title_by_parent_type[item.parent_type] || item.item_name}</Text>
                    </Col>

                    <Col xs md={9}>
                        <Text as="p" style={{fontWeight: 'bold'}}>{item_title_by_parent_type[item.parent_type] || 'Item'}</Text>
                    </Col>
                    <Col xs={false} md={3}>
                        <Text as="p" style={{fontWeight: 'bold'}}>Qty</Text>
                    </Col>
                    {item.parent_type == 'PRODUCT' ? state.breakdowns.map((b, i) => (
                        <Col
                            xs={true}
                            xsStyle="text-align: center; text-align: -webkit-center;"
                            smStyle="text-align: initial;"
                            key={`item-breakdown-${b.breakdown_id}-${b.color_id}-${b.size_id}-${b.quantity}-${i}-${b.product_sku_id}`}
                        >
                            <ItemBreakdown
                                breakdown={b}
                                index={i}
                                onChange={onChangeBreakdown}
                                item_colors={item_colors}
                                item_sizes={item_sizes}
                            />
                        </Col>
                    )) : <>
                        <Col xs md={9}>
                            <Text as="p">
                                {item.item_name}
                            </Text>
                        </Col>
                        <Col xs md={3}>
                            <NumberInput
                                type="text"
                                style={{height: '38px'}}
                                id={`item_qty-${index}`}
                                onFocus={e => e.target.select()}
                                onBlur={(e) => {
                                    const total_units = e.target.value;
                                    const total_subtotal = state.unit_price * parseFloat(total_units);
                                    setState(s => ({...s, total_units, total_subtotal}));
                                }}
                                localeOptions={{maximumFractionDigits: 4,}}
                                defaultValue={state.total_units}
                                icon={String.fromCodePoint(parseInt(10006, 10))}
                                iconStyles={{marginLeft: '-25px', marginTop: '7px', color: themeOptions.colors.primary}}
                                onClickIcon={val => {
                                    setState(s => ({...s, total_units: 0, total_subtotal: 0, quantity: 0}));
                                    return 'clear';
                                }}
                                min={0}
                            />
                        </Col>
                    </>}
                </Row>
            </Col>
        </Row>
    );
}

function parseBreakdown(item_index, index, b = {}) {
    return {
        ...b,
        index,
        item_index,
        old_color_id: b.color_id,
        old_size_id: b.size_id,
        old_quantity: b.quantity,
        old_product_sku_id: b.product_sku_id,
        old_total_subtotal: b.total_subtotal,
    };
}

function ItemBreakdown({ index, item_colors=[], item_sizes=[], breakdown, onChange }) {
    const [state, setState] = useState(breakdown);

    function onUpdate(data={}) {
        setState(s => ({ ...s, ...data, index, }));
    }

    useEffect(() => {
        breakdown && onUpdate(breakdown);
    }, []);

    useEffect(() => {
        if (state && onChange) {
            onChange(index, state);
        }
    }, [state, index, onChange]);

    return (
        <Row>
            <Col xs md={9}>
                <Text as="p">
                    Size: {_.get(item_sizes, `${state.size_id}.size_name`, '')} - Color: {_.get(item_colors, `${state.color_id}.color_name`, '')}
                </Text>
            </Col>
            <Col xs md={3}>
                <NumberInput
                    type="text"
                    style={{height: '38px'}}
                    id={`item_qty-${index}`}
                    onFocus={e => e.target.select()}
                    onBlur={(e) => {
                        const quantity = e.target.value;
                        const total_subtotal = state.unit_price * parseFloat(quantity);
                        onUpdate({quantity, total_subtotal});
                    }}
                    localeOptions={{maximumFractionDigits: 4,}}
                    defaultValue={state.quantity}
                    icon={String.fromCodePoint(parseInt(10006, 10))}
                    iconStyles={{marginLeft: '-25px', marginTop: '7px', color: themeOptions.colors.primary}}
                    onClickIcon={val => {
                        setState(s => ({...s, total_units: 0, total_subtotal: 0, quantity: 0}));
                        return 'clear';
                    }}
                    min={0}
                />
            </Col>
        </Row>
    );
}

export default ReorderOrder;
