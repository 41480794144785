import { createStore, createSlice } from '@reduxjs/toolkit';
import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import _ from 'lodash';

import api from '../middleware/api';
import { reducers as coreReducers } from '../reducers/core';
import { createReducerManager }  from './reducerManager';
import { defaultReduce, oauth } from '../utils';
import { order_types_status } from '../components/client-portal/utils';
import { window } from '../global';

const defaultEntitiesState = typeof window.initialState !== "undefined" && window.initialState.entities ? window.initialState.entities : {};
const defaultDisplatState = typeof window.initialState !== "undefined" && window.initialState.display ? window.initialState.display : {};

const clientPortalSlice = createSlice({
    name: 'client_portal',
    initialState: {
        entities: {
            projects: {},
            clients: {},
            orders: {},
            items: {},
            shops: {},
            colors: {},
            sizes: {},
            reorder: {},
            event_types: {},
            client_contact: {},
            client_contact_loaded: false,
            portal_data: {},
            ...defaultEntitiesState,
        },
        display: {
            messages: {},
            loading: {},
            entities: {},
            toasts: [],
            ...defaultDisplatState,
        },
        dropdowns: {
            projects: [],
        },
        temp: {
            projects: {
                check_total: true,
                totals: 0,
            },
            projects_files: [],
        },
    },
    reducers: {
        fetchProjectsSuccess(state, action) {
            const ordered_projects = _.orderBy(
                action.payload,
                [
                    'is_open',
                    'order_date_full',
                    'job_number',
                    v => order_types_status[v.order_type][v.status_name].order,
                ],
                ['desc', 'desc', 'desc', 'asc']
            );
            const jobIds = [];
            state.entities.projects = ordered_projects.reduce((acc, v) => {
                jobIds.push(v.job_id);
                return {...acc, [v.job_id]: v};
            }, {});
            state.dropdowns.projects = [...jobIds];
        },
        fetchProjectsFilesSuccess(state, action) {
            state.temp.projects_files = [
                ...new Set(state.temp.projects_files)
                    .add(action.payload.job_id),
            ];
            if (state.entities.projects[action.payload.job_id]) {
                state.entities.projects[action.payload.job_id] = {
                    ...state.entities.projects[action.payload.job_id],
                    files: action.payload.files,
                };
            }
        },
        fetchClientsSuccess(state, action) {
            state.entities.clients = defaultReduce(action.payload, 'client_id');
        },
        fetchOrdersSuccess(state, action) {
            state.entities.orders = defaultReduce(action.payload, 'order_id');
            action.payload.forEach(o => {
                o.items.forEach(v => {
                    state.entities.items[v.item_id] = v;
                });
            });
        },
        fetchOrderSuccess(state, action) {
            state.entities.orders[action.payload.order_id] = action.payload;
            action.payload.items.forEach(v => {
                state.entities.items[v.item_id] = v;
            });
        },
        fetchColorsSuccess(state, action) {
            state.entities.colors = {
                TBD: {color_id: 'TBD', color_name: 'TBD'},
                ...defaultReduce(action.payload, 'color_id'),
            };
        },
        fetchSizesSuccess(state, action) {
            state.entities.sizes = {
                TBD: {size_id: 'TBD', size_name: 'TBD'},
                ...defaultReduce(action.payload, 'size_id'),
            };
        },
        fetchEventTypesSuccess(state, action) {
            state.dropdowns.event_types = action.payload.map(v => v.event_type_id);
            state.entities.event_types = {
                ...state.entities.event_types,
                ...defaultReduce(action.payload, 'event_type_id'),
            };
        },

        setDisplayEntity(state, action) {
            state.display.entities[action.payload.key] = action.payload.value;
        },
        setMessage(state, action) {
            state.display.messages[action.payload.key] = {
                message: action.payload.message,
                type: action.payload.type || 'alert',
            };
        },
        setLoading(state, action) {
            state.display.loading[action.payload.key] = action.payload.value;
        },
        addToast(state, action) {
            state.display.toasts.push(action.payload);
        },
        popToast(state, action) {
            state.display.toasts.pop();
        },
        removeToast(state, action) {
            state.display.toasts = state.display.toasts.splice(action.payload, 1);
        },
        clearToasts(state, action) {
            state.display.toasts = [];
        },
        setClientContact(state, action) {
            state.entities.client_contact = action.payload;
            state.entities.client_contact_loaded = true;
        },
        setClientContactLoaded(state, action) {
            state.entities.client_contact_loaded = action.payload;
        },
        setPortalDataStore(state, action) {
            state.entities.portal_data = action.payload;
        },
        setTempData(state, action) {
            state.temp[action.payload.key] = {
                ...(state.temp[action.payload.key] || {}),
                ...action.payload.value,
            };
        },
    },
});

export const {
    fetchProjectsSuccess,
    fetchProjectsFilesSuccess,
    fetchClientsSuccess,
    fetchOrdersSuccess,
    fetchOrderSuccess,
    fetchColorsSuccess,
    fetchSizesSuccess,
    fetchEventTypesSuccess,
    setDisplayEntity,
    setMessage,
    setLoading,
    addToast,
    popToast,
    removeToast,
    clearToasts,
    getProjectsSuccess,
    getClientsSuccess,
    getOrdersSuccess,
    getOrderSuccess,
    setClientContact,
    setClientContactLoaded,
    setPortalDataStore,
    setTempData,
} = clientPortalSlice.actions;

export const clientPortalReducer = clientPortalSlice.reducer;

export const selectors = {
    getClientContact: s => s.client_portal.entities.client_contact || {},
};

const getErrorMsg = (error) => {
    return _.get(error, 'json.errors.error', '');
};

const isErrorType = (error, type = '') => {
    return _.get(error, 'json.errors.error_type', '') == type;
};

const inErrorTypes = (error, types=[], any = false) => {
    const hasErrors = types.filter(v => _.get(error, 'json.errors.error_type', '') == v);
    return any ? hasErrors.length > 0 : hasErrors.length === types.length;
};

export const fetchClientById = (client_id, params={}) => async (dispatch, getState) => {
    dispatch(setLoading({key: 'clients', value: true}));
    try {
        const resp = await oauth('GET', 'page/view-client', {object_id: client_id, public: true, check_portal: true, ...params});
        dispatch(fetchClientsSuccess(_.values(resp.json.payload.entities.clients)));
        dispatch(setLoading({key: 'clients', value: false}));
        return resp.json;
    } catch (error) {
        console.error(error);
        dispatch(addToast({
            key: client_id,
            type: 'error',
            message: inErrorTypes(error, ['login', 'restrict_to_user_projects'], true)
                ? getErrorMsg(error)
                : 'Unable to get projects',
        }));
        dispatch(setLoading({key: 'clients', value: false}));

        let redirectTo = '';
        if (isErrorType(error, 'login')) {
            redirectTo = window.location.pathname.replace("/projects", "/");
        }
        return { error, redirectTo, };
    }
};

export const fetchClientProjects = (client_id, search_query='', params={}) => async (dispatch, getState) => {
    const cc = selectors.getClientContact(getState());
    dispatch(setLoading({key: 'projects', value: true}));
    try {
        const resp = await oauth('GET', 'client-portal/view-client-projects', {client_id, search_query, email: cc.contact_email, ...params});
        dispatch(fetchProjectsSuccess(resp.json.client_projects));
        if (resp.json.totals) {
            dispatch(setTempData({
                key: 'projects',
                value: {totals: resp.json.totals},
            }));
        }
        dispatch(setLoading({key: 'projects', value: false}));
        return resp.json;
    } catch (error) {
        console.error(error);
        dispatch(addToast({
            key: client_id,
            type: 'error',
            message: inErrorTypes(error, ['login', 'restrict_to_user_projects', 'projects_over_limit'], true)
                ? getErrorMsg(error)
                : 'Unable to get projects',
        }));
        dispatch(setLoading({key: 'projects', value: false}));

        let redirectTo = '';
        if (isErrorType(error, 'login')) {
            redirectTo = window.location.pathname.replace("/projects", "/");
        }
        return { error, redirectTo, };
    }
};

export const fetchClientProjectsFiles = (client_id, params={job_id: '', order_id: ''},) => async (dispatch, getState) => {
    if (!params.job_id || !params.order_id) {
        return;
    }

    const fetchedJobIds = getState().client_portal.temp.projects_files;
    if (fetchedJobIds && fetchedJobIds.length > 0 &&
        fetchedJobIds.indexOf(params.job_id) !== -1) {
        return;
    }

    const cc = selectors.getClientContact(getState());
    dispatch(setLoading({key: 'projects-files', value: true}));
    try {
        const resp = await oauth('GET', 'client-portal/view-client-projects-files', {client_id, email: cc.contact_email, ...params,});
        dispatch(fetchProjectsFilesSuccess({ files: resp.json.files, job_id: resp.json.job_id, order_id: resp.json.order_id, }));
        if (resp.json.totals) {
            dispatch(setTempData({
                key: 'projects-files',
                value: {totals: resp.json.totals},
            }));
        }
    } catch (error) {
        console.error(error);
    }
    dispatch(setLoading({key: 'projects-files', value: false}));
};

export const fetchSalesOrderById = (order_id, params={}) => async (dispatch, getState) => {
    const cc = selectors.getClientContact(getState());
    dispatch(setLoading({key: 'orders', value: true}));
    try {
        const resp = await oauth('GET', `client-portal/view-order`, {order_id: order_id, client_id: cc.company_id, email: cc.contact_email, ...params});
        dispatch(fetchOrderSuccess(resp.json.order));
        dispatch(setLoading({key: 'orders', value: false}));
        return resp.json;
    } catch (error) {
        console.error(error);
        dispatch(addToast({
            key: order_id,
            type: 'error',
            message: inErrorTypes(error, ['login'], true)
                ? getErrorMsg(error)
                : "Unable to get order",
            }));
        dispatch(setLoading({key: 'orders', value: false}));
        let redirectTo = '';
        if (isErrorType(error, 'login')) {
            redirectTo = window.location.pathname.replace("/projects", "/");
        }
        return { error, redirectTo, };
    }
};

export const fetchOrderDropdowns = (order_id, params={}) => async (dispatch, getState) => {
    const cc = selectors.getClientContact(getState());
    dispatch(setLoading({key: 'order_dropdowns', value: true}));
    try {
        const resp = await oauth('GET', 'client-portal/view-order-dropdowns', {order_id, client_id: cc.company_id, email: cc.contact_email, ...params});
        dispatch(fetchColorsSuccess(resp.json.colors));
        dispatch(fetchSizesSuccess(resp.json.sizes));
    } catch (error) {
        console.error(error);
        // dispatch(addToast({ key: 'order_dropdowns', type: 'error', message: "Unable to get order data", }));
    }
    dispatch(setLoading({key: 'order_dropdowns', value: false}));
};

export const reorderOrder = (order_id, items, notes='', params={}) => async (dispatch, getState) => {
    const cc = selectors.getClientContact(getState());
    dispatch(setLoading({key: 'reorder', value: true}));
    dispatch(setDisplayEntity({key: 'reorder', value: 'starting'}));
    try {
        await oauth('POST', 'client-portal', {_action: 'reorder', order_id, items, notes, client_id: cc.company_id, email: cc.contact_email, ...params});
        dispatch(setDisplayEntity({key: 'reorder', value: 'done'}));
    } catch (error) {
        console.error(error);
        dispatch(addToast({
            key: 'reorder',
            type: 'error',
            message: inErrorTypes(error, ['login', 'create_order', 'find_order', 'permissions'], true)
                ? getErrorMsg(error)
                : "Unable to re-order",
            }));
        dispatch(setDisplayEntity({key: 'reorder', value: 'error'}));
    }
    dispatch(setLoading({key: 'reorder', value: false}));
};

export const createProject = (client_id, data={}) => async (dispatch, getState) => {
    const cc = selectors.getClientContact(getState());
    dispatch(setLoading({key: 'create_project', value: true}));
    dispatch(setDisplayEntity({key: 'create_project', value: 'starting'}));
    try {
        await oauth('POST', 'client-portal', {_action: 'create-project', client_id, email: cc.contact_email, ...data});
        dispatch(setDisplayEntity({key: 'create_project', value: 'done'}));
    } catch (error) {
        console.error(error);
        dispatch(addToast({
            key: 'create_project',
            type: 'error',
            message: inErrorTypes(error, ['login'], true)
                ? "Not logged in, please refresh page and login."
                : "Unable to create project",
            }));
        dispatch(setDisplayEntity({key: 'create_project', value: 'error'}));
    }
    dispatch(setLoading({key: 'create_project', value: false}));
};

export const fetchEventTypes = (params={'max-results': 4294967296}) => async (dispatch, getState) => {
    const cc = selectors.getClientContact(getState());
    dispatch(setLoading({key: 'event_types', value: true}));
    try {
        const resp = await oauth('GET', 'client-portal/view-event-types', {client_id: cc.company_id, email: cc.contact_email, ...params,});
        dispatch(fetchEventTypesSuccess(resp.json.events));
    } catch (error) {
        console.error(error);
        // dispatch(addToast({ key: 'event_types', type: 'error', message: "Unable to get event types", }));
    }
    dispatch(setLoading({key: 'event_types', value: false}));
};

// reducers
export const reducers = {
    ...coreReducers,
    entities:coreReducers.entities,
    display: coreReducers.display,
    client_portal: clientPortalReducer,
};

export default function configureStore(initialState) {
    const reducerManager = createReducerManager({
      ...reducers,
      scriptName: (state = "") => state,
    });
    const store = createStore(
      reducerManager.reduce,
      initialState,
      compose(
        applyMiddleware(thunk, api),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
          ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
          : f => f
      )
    );
    store.attachReducers = (newReducers) => {
      reducerManager.attachReducers(newReducers);
      store.replaceReducer(reducerManager.reduce);
    };
    return store;
}
